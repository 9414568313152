import React from 'react';
import PropTypes from 'prop-types';
import { createComponent, FelaComponent } from 'react-fela';
import Button from '../Button';
import ackeeLogo from '../../images/ackee_logo_black.svg';
import stastnyImg from '../../images/testimonials/img_stastny.png';
import zahorskyImg from '../../images/testimonials/img_zahorsky.png';

import style, { mailStyle, leftBoxStyle, rightBoxStyle, boxWrapperStyle, footerContainerStyle } from './Contact.style';

const ALinkFela = createComponent(mailStyle, 'a', ['href']);

class Contact extends React.Component {
    // componentDidMount() {
    //     const { isFormSuccesfull } = this.props;
    //     if (isFormSuccesfull) {
    //         this.successMessage.scrollIntoView();
    //         window.dataLayer.push({
    //             'event': 'InteractionForm',
    //             'eventCategory': 'Form',
    //             'eventAction': 'Submit',
    //             'eventLabel': 'Lead',
    //             'eventValue': ''
    //         });
    //     }
    // }

    render() {
        const { isFormSuccesfull } = this.props;
        return (
            <FelaComponent style={style} as="section">
                <div
                    id="contact"
                    className="fluid-container high-flex-container flex-column flex-middle"
                    style={{ paddingTop: '10%', paddingBottom: '5%' }}
                >
                    <FelaComponent style={boxWrapperStyle} as="div">
                        <FelaComponent style={leftBoxStyle} as="div">
                            <h2>Vyzkoušejte Expenses</h2>
                            <p>Zdarma! Budeme rádi za vaši zpětnou vazbu.</p>
                            <form
                                className="high-flex-container flex-column flex-middle"
                                action="https://formspree.io/ex@ackee.cz"
                                method="post"
                            >
                                <label htmlFor="mail">Váš e-mail</label>
                                <input type="text" name="mail" required placeholder="Např. novak@gmail.com" />
                                <input type="hidden" name="_next" value="#done" />
                                {isFormSuccesfull && (
                                    <p
                                        ref={el => {
                                            this.successMessage = el;
                                        }}
                                        style={{ fontWeight: 'bold', maxWidth: '70%' }}
                                    >
                                        Nyní vytváříme Váš demo účet. Během pár hodin Vás budeme kontaktovat.
                                    </p>
                                )}
                                <br />
                                <Button text="Zkusit DEMO" />
                                <br />
                                <p>Pokud máte jakékoliv dotazy, napište nám na tento e-mail</p>
                                <ALinkFela as="a" href="mailto:ex@ackee.cz">
                                    ex@ackee.cz
                                </ALinkFela>
                            </form>
                        </FelaComponent>
                        <FelaComponent style={rightBoxStyle} as="div">
                            <h2>Zkušenosti uživatelů</h2>
                            <div className="Testimonial">
                                <p>Zima je u nás nevyzpytatelná, tak alespoň ve výdajích a účetnictví mám pořádek.</p>
                                <div className="Testimonial-author">
                                    <img src={stastnyImg} alt="daniel stastny" />
                                    <div className="flex-column">
                                        <h4>Daniel Šťastný</h4>
                                        <p>Majitel Ski areálu</p>
                                    </div>
                                </div>
                            </div>
                            <div className="Testimonial">
                                <p>
                                    Produkt jako Expenses jsem hledal už dlouho. Potřebuji mít firemní náklady pod
                                    kontrolou. Produkt je velice jednoduchý a reporty mám dostupné na pár kliknutí.
                                </p>
                                <div className="Testimonial-author">
                                    <img src={zahorskyImg} alt="daniel stastny" />
                                    <div className="flex-column">
                                        <h4>Vojtěch Záhorský</h4>
                                        <p>Founder at Hiremotely</p>
                                    </div>
                                </div>
                            </div>
                        </FelaComponent>
                    </FelaComponent>
                    <FelaComponent style={footerContainerStyle} as="div">
                        <p>Developed by</p>
                        <a href="https://www.ackee.cz" target="_blank">
                            <img src={ackeeLogo} alt="Ackee s.r.o." />
                        </a>
                    </FelaComponent>
                </div>
            </FelaComponent>
        );
    }
}

Contact.propTypes = {};

export default Contact;
