export default {
    fontFamily: 'Soleil, sans-serif',
    colors: {
        black: '#000000',
        white: '#FFFFFF',
        yellow: '#FFD520',
        purple: '#7100FF',
        red: '#FF2106',
        grey: '#EDEDF2',
        darkBlue: '#10092D',
        blue: '#0547FF',
        greyBorder: '#C3C3CB',
    },
    paddings: {
        section: {
            vertical: '10%',
            horizontal: '6%',
        },
    },
};
