const style = ({ theme }) => ({
    backgroundColor: theme.colors.white,

    '> .fluid-container': {
        paddingBottom: 0,
    },

    '& .mobile-img': {
        display: 'none',
        width: '100%',

        '@media (max-width: 768px)': {
            display: 'inline-block',
        },
    },
});

const commonPadding = {
    paddingLeft: 30,
    paddingTop: 20,
};

export const boxStyle = ({ theme }) => ({
    width: '100%',
    minHeight: 0,
    paddingRight: '5%',

    '@media (min-width: 768px)': {
        width: '27%',
        textAlign: 'right',
        display: 'inline-block',
        minHeight: 530,
    },

    '@media (max-width: 768px)': {
        '> p': {
            paddingLeft: 0,
        },
        '> h2': {
            paddingLeft: 0,
        },
        '& .gatsby-image-wrapper': {
            height: '0 !important',
            minHeight: '0 !important',
        },
    },

    '> h2': commonPadding,
    '> p': commonPadding,
});

export const boxStyleRight = ({ theme }) => ({
    width: '100%',
    minHeight: 0,
    marginBottom: 100,
    marginTop: 0,

    '@media (min-width: 768px)': {
        width: '66%',
        textAlign: 'left',
        display: 'inline-block',
        minHeight: 530,
    },

    '@media (max-width: 768px)': {
        '& .gatsby-image-wrapper': {
            height: '0 !important',
            minHeight: '0 !important',
        },
    },
});

export const boxStyleWrapper = ({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',

    '@media (max-width: 768px)': {
        flexDirection: 'column',
    },
});

export default style;
