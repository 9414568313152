import bgImage from '../../images/footer_image.jpg';

const style = ({ theme }) => ({
    // backgroundImage: `url(${bgImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.colors.grey,

    '& form': {
        width: '100%',
    },

    '& p': {
        maxWidth: 'unset',
        textAlign: 'center',
        margin: '20px 0',
    },

    '& input[type=text]': {
        border: `2px solid ${theme.colors.greyBorder}`,
        borderRadius: 4,
        width: 'unset',
        padding: 22,
        fontSize: '1.5rem',
        lineHeight: '1.6rem',
        margin: '50px 0',
        marginBottom: 20,
    },

    '& input[type=submit]': {
        marginTop: 20,
    },

    '@media (min-width: 768px)': {
        '& p': {
            maxWidth: '40%',
        },

        '& input[type=text]': {
            width: '40%',
            maxWidth: 500,
        },
    },
});

export const leftBoxStyle = ({ theme }) => ({
    backgroundColor: theme.colors.white,
    padding: '8.9% 8%',
    textAlign: 'left',
    marginBottom: 60,

    '& form': {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },

    '& p': {
        maxWidth: 'unset',
        textAlign: 'left',
        margin: '20px 0',
    },

    '& label': {
        margin: 0,
    },

    '& input[type=text]': {
        border: `3px solid ${theme.colors.black}`,
        borderRadius: 4,
        width: 'calc(100% - 36px)',
        padding: '22px 16px',
        fontSize: '1rem',
        lineHeight: '1rem',
        marginTop: 8,
        marginBottom: 0,
    },

    '& input[type=submit]': {
        marginTop: 10,
        width: '100%',
    },

    '@media (min-width: 768px)': {
        margin: '2%',
        padding: '5.9% 5%',
        width: '40%',

        '& p': {
            maxWidth: '100%',
        },

        '& input[type=text]': {
            width: 'calc(100% - 36px)',
        },
    },
});

export const rightBoxStyle = ({ theme }) => ({
    margin: '2%',
    padding: '0 5%',
    textAlign: 'left',

    '& form': {
        width: '100%',
        alignItems: 'flex-start',
    },

    '& p': {
        maxWidth: 'unset',
        textAlign: 'left',
        margin: '20px 0',
    },

    '& .Testimonial': {
        marginTop: 43,
    },

    '& .Testimonial-author': {
        display: 'flex',

        '& img': {
            width: 60,
            height: 60,
            marginRight: 16,
        },

        '& p': {
            fontSize: '1rem',
            lineHeight: '1.2rem',
            marginTop: 0,
        },

        '& h4': {
            fontSize: '1.2rem',
            lineHeight: '1.6rem',
            marginTop: 8,
        },
    },

    '@media (min-width: 768px)': {
        width: '46%',

        '& p': {
            maxWidth: '100%',
        },
    },
});

export const boxWrapperStyle = ({ theme }) => ({

    '@media (min-width: 768px)': {
        display: 'flex',

    },
});

export const mailStyle = ({ theme }) => ({
    color: theme.colors.blue,
    fontSize: '1.9rem',
    lineHeight: '2rem',
    outline: 'none',
});

export const footerContainerStyle = ({ theme }) => ({
    '& img': {
        maxWidth: 119,
    },

    '& p': {
        color: theme.colors.darkBlue,
        fontSize: '0.75rem',
        lineHeight: '0.875rem',
        maxWidth: 'unset',
        margin: '1.1rem 0',
        opacity: 1,
        marginTop: '6.75rem',
    },

    '@media (min-width: 768px)': {
        '& p': {
            maxWidth: 'unset',
        },
    },
});

export default style;
