import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { createComponent, FelaComponent } from 'react-fela';

import style, { boxStyle, boxStyleLeft } from './Tags.style';

const imgStyles = ({ small, fontSize, color }) => ({
    width: '100%',

    min768px: {
        width: '100%',
        marginLeft: '10%',
        marginTop: '-7%',
    },
});

const ImgFela = createComponent(imgStyles, 'img', ['alt', 'src']);

const Tags = ({ children }) => (
    <StaticQuery
        query={graphql`
            query {
                backgroundImage: file(relativePath: { eq: "tagy-small.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2048) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `}
        render={data => (
            <FelaComponent style={style} as="section">
                <div id="reports" className="fluid-container high-flex-container">
                    <FelaComponent style={[boxStyle, boxStyleLeft]} as="div">
                        <h2>Štítky</h2>
                        <p>
                        V Expenses nejsou žádné přednastavené kategorie. Každá firma je přece jedinečná a sleduje specifické náklady. Systém štítků si navrhnete tak, aby vyhovoval právě vám.
                        </p>
                    </FelaComponent>
                    <FelaComponent style={boxStyle} as="div">
                        <ImgFela src={data.backgroundImage.childImageSharp.fluid.src} />
                    </FelaComponent>
                </div>
            </FelaComponent>
        )}
    />
);

Tags.propTypes = {};

export default Tags;
