const style = ({ theme }) => ({
    backgroundColor: theme.colors.grey,
});

export const titleStyle = ({ theme }) => ({
    fontSize: '2.1rem',
    lineHeight: '2.5rem',
    fontWeight: '700',
});

export const textStyle = ({ isActive }) => ({
    marginTop: 32,
    fontSize: '1.2rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.5px',
    fontWeight: '400',

    '@media (min-width: 768px)': {
        width: '70%',
    },
});

export default style;
