import React from 'react';
import PropTypes from 'prop-types';
import { createComponent, FelaComponent } from 'react-fela';

import style, { imgStyle } from './IconBox.style';
const ImgFela = createComponent(imgStyle, 'img', ['alt', 'src']);

const IconBox = ({ isSmall, align, imageSource, text, title }) => (
    <FelaComponent align={align} isSmall={isSmall} style={style} as="article">
        <ImgFela src={imageSource} alt={title} />
        <div className="IconBox-content">
            <h4>{title}</h4>
            <p>{text}</p>
        </div>
    </FelaComponent>
);

IconBox.propTypes = {
    imageSource: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    isSmall: PropTypes.string,
};

export default IconBox;
