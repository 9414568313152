export const style = ({ theme, isOnTop }) => ({
    padding: isOnTop ? '5px 15px' : '20px 32px',
    textAlign: 'center',
    background: theme.colors.red,
    borderRadius: isOnTop ? 2 : 4,
    color: `${theme.colors.white} !important`,
    fontSize: '1rem',
    lineHeight: '1.1rem !important',
    fontWeight: '500 !important',
    boxShadow: 'none',
    border: 0,
    textDecoration: 'none',
    cursor: 'pointer',

    min768px: {
        padding: '21px 49px',
        padding: isOnTop ? '13px 49px' : '21px 49px',
    }
});

export default style;
