import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { createComponent, FelaComponent } from 'react-fela';

import style, { boxStyle, boxStyleRight } from './Reports.style';

const imgStyles = () => ({
    width: '100%',

    min768px: {
        width: '100%',
        marginLeft: '-10%',
        marginTop: '-7%',
    },
});

const ImgFela = createComponent(imgStyles, 'img', ['alt', 'src']);

const About = ({ children }) => (
    <StaticQuery
        query={graphql`
            query {
                backgroundImage: file(relativePath: { eq: "reporty-small.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2048) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `}
        render={data => (
            <FelaComponent style={style} as="section">
                <div id="reports" className="fluid-container high-flex-container">
                    <FelaComponent style={boxStyle} as="div">
                        <ImgFela src={data.backgroundImage.childImageSharp.fluid.src} />
                    </FelaComponent>
                    <FelaComponent style={[boxStyle, boxStyleRight]} as="div">
                        <h2>Přehledy</h2>
                        <p>
                            Náklady si zobrazíte podle období, a tak hned víte, kdy
                            se dařilo a kdy už tolik ne. Štítky vám zase prozradí, kam peníze tekly.
                        </p>
                    </FelaComponent>
                </div>
            </FelaComponent>
        )}
    />
);

About.propTypes = {};

export default About;
