import bgImage from '../../images/hero_image.jpg'

const style = ({ theme }) => ({
    backgroundImage: `url(${bgImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.colors.white,
});

export const titleStyle = () => ({
    marginBottom: 72,
    marginTop: 72,
});

export const boxStyle = ({ theme }) => ({
    width: '100%',
    minHeight: 0,

    '> p': {
        margin: '50px 0',
    },

    '> a': {
        width: '80%',
        display: 'inline-block'
    },

    '@media (min-width: 768px)': {
        width: '45%',
        display: 'inline-block',
        minHeight: 560,

        '> p': {
            margin: 0,
        },

        '> a': {
            width: 'unset',
        },
    },
});

export default style;
