/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { FelaComponent } from 'react-fela';

import Header from '../components/Header';
import Top from '../components/Top';
import Reports from './Reports';
import Tags from './Tags';
import Exports from './Exports';
import Safety from './Safety';
import How from './How';
import Info from '../components/Info';
import Contact from '../components/Contact';
import withLayout from '../HOC/withLayout';
import SEO from './seo';

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const style = ({ theme }) => ({
  minHeight: '100vh',
  backgroundColor: theme.colors.white,
  overflowX: 'hidden',
});

const Layout = ({ isFormSuccesfull, isAlternativeVersion }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
        <FelaComponent style={style}>
          <SEO />
          <Header />
          <Top isFormSuccesfull={isFormSuccesfull} />
          <Info />
          <Reports />
          <Tags />
          <Exports />
          <Safety />
          <How />
          <Contact isFormSuccesfull={isFormSuccesfull} />
        </FelaComponent>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withLayout(Layout);
