import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { createComponent, FelaComponent } from 'react-fela';
import Button from '../Button';

import style, { boxStyle, titleStyle } from './Top.style';

const imgStyles = ({ small, fontSize, color }) => ({
    marginBottom: '-2.5vh',
    marginLeft: '12%',
    marginTop: 55,
    width: '120%',
    height: 'unset',

    min768px: {
        width: 'unset',
        height: '80vh',
        marginLeft: '-25%',
        marginBottom: '-12vh',
        marginTop: 0,
    },
});

const ImgFela = createComponent(imgStyles, 'img', ['alt', 'src']);

const Top = ({ children }) => (
    <StaticQuery
        query={graphql`
            query {
                placeholderImage: file(relativePath: { eq: "hero_image.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2048) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                iphoneImage: file(relativePath: { eq: "hero_phone.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 2048) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `}
        render={data => (
            <FelaComponent style={style}>
                <div id="about" className="fluid-container high-flex-container" style={{ paddingBottom: '2%' }}>
                    <FelaComponent style={boxStyle} as="div">
                        <FelaComponent style={titleStyle} as="h1">
                            Firemní výdaje online
                            <br />a pod kontrolou.
                        </FelaComponent>
                        <Button href="#contact" text="Zkusit DEMO" />
                    </FelaComponent>
                    <FelaComponent style={boxStyle} as="div">
                        <ImgFela src={data.iphoneImage.childImageSharp.fluid.src} />
                    </FelaComponent>
                </div>
            </FelaComponent>
        )}
    />
);

Top.propTypes = {};

export default Top;
