import React from 'react';
import { FelaComponent } from 'react-fela';
import { StaticQuery, graphql } from 'gatsby';
import debounce from 'lodash.debounce';

import Button from '../Button';
import { wrapperStyle, headerStyle, headerMobileStyle, headerMobileWrapperStyle } from './Header.style';

class Header extends React.Component {
    static DEBOUNCE_TIME = 1000 / 60;

    static SCROLL_OFFSET = 0;
    static DIRECTION_UP = 'up';
    static DIRECTION_DOWN = 'down';

    constructor(props) {
        super(props);

        this.state = {
            show: true,
            headerHeight: null,
            menuVisible: false,
        };

        this.lastScrollTop = 0;

        this.ref = React.createRef();

        this.handleScroll = debounce(this.handleScrollUnbounced, Header.DEBOUNCE_TIME);
        this.handleResize = debounce(this.handleResizeUnbounced, Header.DEBOUNCE_TIME);
    }

    componentDidMount() {
        this.handleScroll();
        this.handleResize();

        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleDirection = () => {
        const direction = window.pageYOffset - this.lastScrollTop > 0 ? Header.DIRECTION_DOWN : Header.DIRECTION_UP;

        if (direction === Header.DIRECTION_DOWN) {
            this.setState({
                show: true,
            });
        } else {
            this.setState({
                show: false,
            });
        }

        this.lastScrollTop = window.pageYOffset;
    };

    handleScrollTop = () => {
        if (this.lastScrollTop > Header.SCROLL_OFFSET) {
            this.setState({
                isOffTop: false,
            });
        } else {
            this.setState({
                isOffTop: true,
            });
        }
    };

    handleScrollUnbounced = () => {
        this.handleDirection();
        this.handleScrollTop();
    };

    handleHeaderHeightChange = () => {
        this.setState({
            headerHeight: this.ref.current.clientHeight,
        });
    };

    handleResizeUnbounced = () => {
        this.handleHeaderHeightChange();
    };

    render() {
        const { show, headerHeight } = this.state;
        return (
            <StaticQuery
                query={graphql`
                    query {
                        logoImage: file(relativePath: { eq: "logo.png" }) {
                            childImageSharp {
                                fluid(maxWidth: 500) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        burgerImage: file(relativePath: { eq: "ic_burger.png" }) {
                            childImageSharp {
                                fluid(maxWidth: 500) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        closeImage: file(relativePath: { eq: "ic_close.png" }) {
                            childImageSharp {
                                fluid(maxWidth: 500) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                `}
                render={data => (
                    <FelaComponent style={wrapperStyle}>
                        {({ className }) => (
                            <div className={className} ref={this.ref}>
                                <FelaComponent
                                    style={headerMobileWrapperStyle}
                                    height={headerHeight}
                                    show={show}
                                    as="div"
                                >
                                    <img
                                        className="Header-logo"
                                        src={data.logoImage.childImageSharp.fluid.src}
                                        style={{ height: 24, width: 160 }}
                                    />
                                    <img
                                        className="Header-burger"
                                        src={data.burgerImage.childImageSharp.fluid.src}
                                        style={{ height: 40, width: 40 }}
                                        onClick={() =>
                                            this.setState({
                                                menuVisible: true,
                                            })
                                        }
                                    />
                                    <FelaComponent style={headerMobileStyle} show={this.state.menuVisible} as="header">
                                        <div>
                                            <div className="row">
                                                <img
                                                    className="Header-logo"
                                                    src={data.logoImage.childImageSharp.fluid.src}
                                                    style={{ height: 24, width: 160 }}
                                                />
                                                <img
                                                    className="Header-close"
                                                    src={data.closeImage.childImageSharp.fluid.src}
                                                    style={{ height: 40, width: 40 }}
                                                    onClick={() =>
                                                        this.setState({
                                                            menuVisible: false,
                                                        })
                                                    }
                                                />
                                            </div>
                                            <ul
                                                onClick={() =>
                                                    this.setState({
                                                        menuVisible: false,
                                                    })
                                                }
                                            >
                                                <li>
                                                    <a href="#info">Co jsou expenses</a>
                                                </li>
                                                <li>
                                                    <a href="#reports">Funkce</a>
                                                </li>
                                            </ul>
                                            <div className="buttonWrapper">
                                                <Button
                                                    href="#contact"
                                                    onClick={() =>
                                                        this.setState({
                                                            menuVisible: false,
                                                        })
                                                    }
                                                    text="Zkusit DEMO"
                                                />
                                            </div>
                                        </div>
                                    </FelaComponent>
                                </FelaComponent>
                                <FelaComponent style={headerStyle} height={headerHeight} show={show} as="header">
                                    <img
                                        className="Header-logo"
                                        src={data.logoImage.childImageSharp.fluid.src}
                                        style={{ height: 28, width: 190 }}
                                    />
                                    <ul>
                                        <li>
                                            <a href="#info">Co jsou expenses</a>
                                        </li>
                                        <li>
                                            <a href="#reports">Funkce</a>
                                        </li>
                                        <li>
                                            <Button isOnTop href="#contact" text="Zkusit DEMO" />
                                        </li>
                                    </ul>
                                </FelaComponent>
                            </div>
                        )}
                    </FelaComponent>
                )}
            />
        );
    }
}

export default Header;
