import Soleil from '../fonts/Soleil.otf';
import SoleilSemiBold from '../fonts/SoleilSemiBold.otf';
import SoleilBold from '../fonts/SoleilBold.otf';
import SoleilExtraBold from '../fonts/SoleilExtraBold.otf';
import SoleilBlack from '../fonts/SoleilBlack.otf';
import SoleilLight from '../fonts/SoleilLight.otf';

export default [
    {
        name: 'Soleil',
        files: [Soleil],
        options: {
            fontStyle: 'normal',
            fontWeight: '400',
        },
    },
    {
        name: 'Soleil',
        files: [SoleilSemiBold],
        options: {
            fontStyle: 'normal',
            fontWeight: '500',
        },
    },
    {
        name: 'Soleil',
        files: [SoleilSemiBold],
        options: {
            fontStyle: 'normal',
            fontWeight: '600',
        },
    },
    {
        name: 'Soleil',
        files: [SoleilBold],
        options: {
            fontStyle: 'normal',
            fontWeight: '700',
        },
    },
    {
        name: 'Soleil',
        files: [SoleilExtraBold],
        options: {
            fontStyle: 'normal',
            fontWeight: '800',
        },
    },
    {
        name: 'Soleil',
        files: [SoleilBlack],
        options: {
            fontStyle: 'normal',
            fontWeight: '900',
        },
    },
    {
        name: 'Soleil',
        files: [SoleilLight],
        options: {
            fontStyle: 'normal',
            fontWeight: '300',
        },
    },
];
