import React from "react";
import PropTypes from "prop-types";
import { createComponent, FelaComponent } from 'react-fela';

import style from './Button.style';

const ALinkFela = createComponent(
   style,
    'a',
    ['isOnTop', 'href', 'onClick']
);

const LinkFela = createComponent(
    style,
    'input',
    ['isOnTop', 'type', 'onClick', 'value']
);

const Button = ({ text, isOnTop, href, onClick }) => {
    if (href) {
        return <ALinkFela isOnTop={isOnTop} href={href} as='a' onClick={onClick}>{text}</ALinkFela>
    } else {
        return <LinkFela isOnTop={isOnTop} type="submit" onClick={onClick} value={text} />
    }
}

Button.propTypes = {

}

export default Button;
