import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FelaComponent } from 'react-fela';
import { StaticQuery, graphql } from 'gatsby';
import IconBox from './IconBox';

import style, { textStyle, titleStyle } from './Info.style';

const Info = () => {
    return (
        <StaticQuery
            query={graphql`
                query {
                    app: file(relativePath: { eq: "ic_sheet.png" }) {
                        childImageSharp {
                            fluid(maxWidth: 250) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    card2: file(relativePath: { eq: "ic_tag.png" }) {
                        childImageSharp {
                            fluid(maxWidth: 250) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    phone: file(relativePath: { eq: "ic_graph.png" }) {
                        childImageSharp {
                            fluid(maxWidth: 250) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            `}
            render={data => (
                <React.Fragment>
                    <FelaComponent style={style} as="section">
                        <div
                            id="info"
                            className="fluid-container high-flex-container flex-column"
                            style={{ paddingBottom: '6%' }}
                        >
                            <FelaComponent style={titleStyle} as="h2">
                                Co jsou Expenses
                            </FelaComponent>
                            <FelaComponent style={textStyle} as="p">
                                Jednoduchá webová aplikace, kam můžete ukládat všechny své účtenky a faktury. Účetní
                                doklady budete mít na jednom místě v cloudu a firemní výdaje dostanete pod kontrolu.
                            </FelaComponent>
                            <div className="high-flex-container" style={{ marginBottom: 80 }}>
                                <IconBox
                                    align="center"
                                    imageSource={data.app.childImageSharp.fluid.src}
                                    title="Nahrajte doklad"
                                    text="Sken účtenky nebo fakturu v pdf nahrajte do Expenses."
                                />
                                <IconBox
                                    align="center"
                                    imageSource={data.card2.childImageSharp.fluid.src}
                                    title="Přidejte štítek"
                                    text="K dokladu přidejte libovolné množství štítků."
                                />
                                <IconBox
                                    align="center"
                                    imageSource={data.phone.childImageSharp.fluid.src}
                                    title="Získejte kontrolou"
                                    text="Doklady a statistiky výdajů máte
                                    k dispozici na pár kliknutí."
                                />
                            </div>
                        </div>
                    </FelaComponent>
                </React.Fragment>
            )}
        />
    );
};

Info.propTypes = {};

export default Info;
