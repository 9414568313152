const style = ({ theme }) => ({
    backgroundColor: '#F6F6F9',
});

export const titleStyle = ({ theme }) => ({
    fontSize: '2.1rem',
    lineHeight: '2.5rem',
    fontWeight: '700',
});

export const boxStyle = ({ theme }) => ({
    width: 'calc(100% - 40px)',
    minHeight: 0,
    marginTop: 60,
    paddingLeft: 20,
    paddingRight: 10,
    borderLeft: '2px solid #DBDBE5',

    '> h3': {
        fontSize: '1.4rem',
        lineHeight: '1.6rem',
        marginBottom: 10,
    },

    '> p': {
        fontSize: '0.94rem',
        lineHeight: '1.3rem',
        marginBottom: 10,
    },

    '@media (min-width: 768px)': {
        paddingLeft: 30,
        paddingRight: 90,
        width: '27%',
        textAlign: 'left',
        display: 'inline-block',
    },
});

export default style;
