import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { createComponent, FelaComponent } from 'react-fela';

import style, { boxStyle, titleStyle } from './How.style';

const imgStyles = () => ({
    width: '100%',
    maxWidth: 180,

    min768px: {
        width: '100%',
        marginRight: '10%',
        marginTop: '-2%',
    },
});

const ImgFela = createComponent(imgStyles, 'img', ['alt', 'src']);

const How = ({ children }) => (
    <StaticQuery
        query={graphql`
            query {
                securityImage: file(relativePath: { eq: "ic_security4.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 500) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                permissionImage: file(relativePath: { eq: "ic_permission2.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 500) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `}
        render={data => (
            <FelaComponent style={style} as="section">
                <div id="how" className="fluid-container flex-column high-flex-container">
                    <FelaComponent style={titleStyle} as="h2">
                        Jak používat Expenses
                    </FelaComponent>
                    <div className="high-flex-container" style={{ marginBottom: 80 }}>
                        <FelaComponent style={boxStyle} as="div">
                            <h3>Vaše učetní</h3>
                            <p>
                                Vaše účetní skenuje doklady a zakládá je do Expenses. Dle domluvených pravidel jim
                                přiřazuje štítky. Data se automaticky exportují do účetního programu Flexibee nebo
                                posílají e-mailem.
                            </p>
                        </FelaComponent>
                        <FelaComponent style={boxStyle} as="div">
                            <h3>Vaše asistentka</h3>
                            <p>
                                Vaše asistentka skenuje, zakládá a štítkuje doklady. Data pak chodí účetní přímo do
                                Flexibee nebo e-mailem.
                            </p>
                        </FelaComponent>
                        <FelaComponent style={boxStyle} as="div">
                            <h3>Naše učetní</h3>
                            <p>
                                Přejdete k naší účetní firmě, s níž máme spolupráci perfektně vyladěnou, a budete to mít
                                úplně bez práce.
                            </p>
                        </FelaComponent>
                    </div>
                </div>
            </FelaComponent>
        )}
    />
);

How.propTypes = {};

export default How;
