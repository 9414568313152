const style = ({ theme, align, isSmall }) => ({
    width: '60%',
    padding: '12% 20%',
    paddingLeft: align === 'left' ? 0 : '20%',
    textAlign: align,

    '@media (max-width: 768px)': {
        width: '100%',
        padding: '30px 0px',
        display: 'flex',
        paddingBottom: 0,

        '&:first-child': {
            paddingTop: 60,
        },

        '& .IconBox-content': {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
            marginLeft: 20,
        },

        '& h4': {
            marginTop: 0,
        },
    },

    '@media (min-width: 768px)': {
        width: align === 'left' ? '32%' : '22%',
        padding: '7% 5%',
        paddingBottom: 0,
        paddingLeft: align === 'left' ? 0 : '5%',
        display: 'inline-block',
    },

    '& h4': {
        marginTop: 32,
        fontSize: '1.2rem',
        lineHeight: '1.4rem',
        display: 'inline-block',
    },

    '& p': {
        marginTop: 6,
        fontSize: '1rem',
        lineHeight: '1.4rem',
        display: 'inline-block',
        opacity: 0.6,
    },

    '& strong': {
        fontWeight: 800,
    },
});

export const imgStyle = () => ({
    maxWidth: 170,
    width: '100%',

    '@media (max-width: 768px)': {
        width: 60,
        height: 60,
    },
});

export default style;
