export const wrapperStyle = () => ({
    position: 'fixed',
    top: 0,
    zIndex: 999,

    boxSizing: 'border-box',
    width: '100%',
});

export const headerStyle = ({ theme, show }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',

    boxSizing: 'border-box',
    width: '100%',
    height: 'auto',
    padding: '1rem 2rem 1rem 2rem',

    background: show ? theme.colors.white : theme.colors.transparent,
    boxShadow: show ? '0px 3px 3px 0px rgba(0,0,0,0.16)' : 'none',

    transition: 'all 0.35s',

    '& img': {
        maxWidth: 200,
    },

    '& a': {
        color: theme.colors.black,
        fontWeight: 800,
        border: 'none',
        fontSize: '0.8rem',
        textDecoration: 'none',
    },

    '& a:hover': {
        color: theme.colors.red,
    },

    '& ul': {
        display: 'flex',
    },

    '& li': {
        margin: '0 15px',
    },

    min768px: {
        flexDirection: 'row',
        height: 75,
    },

    '@media (max-width: 768px)': {
        display: 'none',

        '& a': {
            fontSize: '0.6rem',
        },
        '& li': {
            margin: '0 5px',
            marginTop: 10,
        },
        '& img': {
            // maxWidth: 100,
        },
    },
});

export const headerMobileWrapperStyle = ({ theme, show }) => ({
    display: 'none',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '30px 24px',

    '@media (max-width: 768px)': {
        display: 'flex',
    },
});

export const headerMobileStyle = ({ theme, show }) => ({
    width: 'calc(100% - 48px)',
    height: 'calc(100vh - 60px)',
    display: show ? 'flex' : 'none',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '30px 24px',
    position: 'absolute',
    top: 0,
    right: 0,
    opacity: show ? 1 : 0,
    background: theme.colors.white,
    transition: 'all 0.3s ease',

    '& .row': {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    '& .buttonWrapper': {
        display: 'flex',
        marginTop: 50,
    },

    '& .buttonWrapper a': {
        position: 'absolute',
        bottom: 24,
        left: 24,
        right: 24,
    },

    '& a': {
        color: theme.colors.black,
        fontWeight: 500,
        border: 'none',
        fontSize: '1rem',
        textDecoration: 'none',
    },

    '& ul': {
        display: 'flex',
        flexDirection: 'column',
    },

    '& li': {
        marginTop: 40,
    },

    '@media (min-width: 400px)': {
        maxWidth: 320,

        '& .Header-logo': {
            opacity: 0,
        },

        '& .buttonWrapper a': {
            position: 'relative',
            bottom: 'unset',
            left: 'unset',
            right: 'unset',
        },
    },
});
