import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { createComponent, FelaComponent } from 'react-fela';

import style, { boxStyle, boxStyleRight, boxStyleWrapper } from './Safety.style';

const imgStyles = () => ({
    width: '100%',
    maxWidth: 180,

    min768px: {
        width: '100%',
        marginRight: '10%',
        marginTop: '-2%',
    },
});

const ImgFela = createComponent(imgStyles, 'img', ['alt', 'src']);

const About = ({ children }) => (
    <StaticQuery
        query={graphql`
            query {
                securityImage: file(relativePath: { eq: "ic_security4.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 500) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                permissionImage: file(relativePath: { eq: "ic_permission2.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 500) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `}
        render={data => (
            <FelaComponent style={style} as="section">
                <div id="safety" className="fluid-container high-flex-container">
                    <FelaComponent style={boxStyleWrapper} as="div">
                        <FelaComponent style={boxStyle} as="div">
                            <ImgFela src={data.securityImage.childImageSharp.fluid.src} />
                        </FelaComponent>
                        <FelaComponent style={[boxStyle, boxStyleRight]} as="div">
                            <h2>Vaše doklady jsou v Bezpečí!</h2>
                            <p>
                                Přílohy nákladů jsou uloženy na Google Cloud Storage a přístup k nim mají pouze
                                autorizované osoby s podepsanými odkazy, jejichž expirace je omezená.
                                <br />
                                <br />
                                Ostatní data jsou uložena bez šifrování v relační databázi a přístup k nim je řízen na
                                základě oprávnění.
                            </p>
                        </FelaComponent>
                    </FelaComponent>
                    <FelaComponent style={boxStyleWrapper} as="div">
                        <FelaComponent style={boxStyle} as="div">
                            <ImgFela src={data.permissionImage.childImageSharp.fluid.src} />
                        </FelaComponent>
                        <FelaComponent style={[boxStyle, boxStyleRight]} as="div">
                            <h2>Oprávnění</h2>
                            <p>
                                Některé doklady jsou obzvlášť citlivé. Proto můžete svým zaměstnancům přidělit
                                odpovídající práva. Sami si tak určíte, kdo může daný doklad vidět.
                            </p>
                        </FelaComponent>
                    </FelaComponent>
                </div>
            </FelaComponent>
        )}
    />
);

About.propTypes = {};

export default About;
